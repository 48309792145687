@import "../../../scss/variables";
@import '../../../scss/mixins';

.react-select-user {
    width: 200px;

    .select__control {
        @include select-control-focused;
        &:hover,
        &:active {
            border-color: #f8AE8B;
            outline: none;
            box-shadow: $primaryBoxShadow;
        }

        .select__indicators {
            display: none;
        }
    }
    @include select-menu;
}