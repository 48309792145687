@import "../../scss/variables";

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.even{
    background-color: rgba(0,0,0,.05);
}
.goal-item {
    .form-control[readonly],
    .form-control.read-only {
        background-color: #FFF;
        opacity: 1;
    }
    .form-control,
    .index p {
        min-height: 35px;
        font-size: 14px;
        font-weight: 400;
        width: auto;
    }
    .points {
        input {
            width: 64px;
            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
    .loss {
        input {
            width: 64px;
            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
    .invalid {
        border: 1px solid #dc3545 !important;
        box-shadow: 0px 0px 8px #dc3545;
    }

    .warn {
        border: 2px solid $warning;
    }
    .buttons{
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        padding: 18px 12px;

        svg {
            margin: 0 4px;
            padding-top: 2px;
            transition: transform 250ms ease-out;
            will-change: transform;

            &:hover {
                transition: transform 150ms;
                transform: translateY(2px);
            }
        }
    }
    .action-buttons {
        padding: 18px 12px;

        svg {
            margin: 0 4px;
            padding-top: 2px;
            transition: transform 250ms ease-out;
            will-change: transform;
            &:hover {
                transition: transform 150ms;
                transform: translateY(2px);
            }
        }
    }

}
.goal-item:hover{
    background-color: #B8B8BA;
}