.circle-progress-bar {
    svg {
        width: 90px;
        filter: drop-shadow(0px 0px 6px #D1D1D1);
    }
    &.lengthy-str {
        .text {
            h6 {
                font-size: 10px;
            }
        }
    }
    .not-started {
        svg {
            font-size: 76px;
            color: #AFAFAF;
        }
    }

    .not-scored {
        display: flex;
        flex-direction: column;
        align-items: center;

        h5 {
            color: #C6C6C6;
            font-size: 16px;
            margin-bottom: 0;
        }
    }
}