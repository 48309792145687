@import './variables';

@mixin cardEffect {
    background: #fff;
    margin: 12px;
    border-radius: 8px;
    box-shadow: 10px 10px 25px rgba(119, 119, 119, 0.5);
}
@mixin md-text($size: 20px) {
    font-size: $size;
    font-weight: 400;
}

@mixin rg-text($size: 22px) {
    font-size: $size;
    font-weight: 400;
}
@mixin padding($size: 18px) {
    padding: $size;
}
@mixin btn-hover {
    box-shadow: 4px 4px 12px black;
}
@mixin table-header {
    table {
        position: relative;
        font-size: 14px;
        margin-bottom: 0;

        thead {
            background: #f8f8f8;
            font-weight: 500;
            tr {
                border-radius: 8px;
                th {
                    background: #5f6062;
                    color: #fff;
                    box-shadow: 0px 15px 20px 0px rgba(119, 119, 119, 0.5);
                }
            }
        }
        &.table-hover tbody tr:hover {
            background-color: #B8B8BA;
        }
    }
}

@mixin select-menu {
    .select__menu {
        .select__menu-list {
            .select__option {
                &.select__option--is-focused {
                    background: $primaryColorRGB;
                }

                &.select__option--is-selected {
                    background: $primaryColor;
                }
            }
        }
    }
}
@mixin select-control-focused {
    &.select__control--is-focused {
        background-color: #fff;
        border-color: #f8ae8b;
        outline: 0;
        box-shadow: $primaryBoxShadow;
    }
}
@mixin media-queries($type : $mobile) {
    @if map-has-key($breakpoints, $type) {

        $breakpoint-value: map-get($breakpoints, $type);

        @media (max-width: $breakpoint-value) {
            @content;
        }

    }

    @else {
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}