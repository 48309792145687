@import '../../scss/mixins';

.submit-modal {
    .modal-content {
        @include padding();
    }
    .modal-buttons {
        display: inline-flex;
        justify-content: space-around;
        .btn {
            min-width: 100px;
        }
    }
    .bold-text {
        font-weight: bold;
    }
}

.modal-backdrop.show{
    opacity: 0.8;
}
