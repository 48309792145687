.comment-item {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 16px;

    .user-icon {
        flex-basis: 40px;
        display: flex;
        justify-content: center;
        margin-right: 16px;

        div {
            position: relative;
            font-size: 14px;
            width: 30px;
            height: 30px;
            border-radius: 50%;

            span {
                position: absolute;
                top: 5px;
                left: 6px;
            }

            &.em {
                background-color: #000;
                color: #FFF;
            }

            &.ma {
                background-color: #757575;
                color: #FFF;
            }
        }
    }

    .details {
        display: flex;
        flex-direction: column;
        justify-content: left;
        width: 100%;

        p {
            margin-bottom: 0;
        }

        .heading,
        .comment,
        .buttons {
            display: inline-flex;
            justify-content: flex-start;
        }

        .heading {
            align-items: baseline;

            span {
                margin-left: 12px;
                font-size: 12px;
                color: #757575;
            }
        }

        .comment {
            p {
                display: block;

                &::first-letter {
                    // font-weight: bold;
                    text-transform: uppercase;
                }
            }
            &::before {
                content: '\201c';
            }

            &::after {
                content: '\201d';
            }
        }

        .buttons {
            justify-content: flex-start;

            .btn-link {
                margin: 0;
                padding: 0;
                padding-right: 12px;
            }
        }
    }
}