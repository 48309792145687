@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

@import './variables';

$body-bg: #F5F6FA;
$font-family-base: 'Roboto',
sans-serif;

// pagination
$pagination-color: $primaryColor;
$pagination-hover-color: $primaryColor;
$pagination-active-color: $color3;
$pagination-active-bg: $primaryColor;
$pagination-active-border-color: $primaryColor;
$pagination-focus-outline: $primaryColor;
$pagination-focus-box-shadow: $primaryBoxShadow;
$theme-colors: (primary: $primaryColor,
    secondary: $secondaryColor,
    success: $success,
    info: $info,
    warning: $warning,
    message: $brandBlue,
    danger: $danger,
    light: $light,
    dark: $dark);
// buttons
$btn-focus-box-shadow: $primaryBoxShadow;
$btn-active-box-shadow: $primaryBoxShadow;

@import '../node_modules/bootstrap/scss/bootstrap';
