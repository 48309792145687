@import '../../scss/_mixins.scss';
@import "../../scss/variables";

.stay-focused {
    @include cardEffect;
    flex-basis: 50%;
    @include padding();
    width: 100%;
    overflow-x: auto;
    background: #D6D6D7;

    h1 {
        @include md-text(20);
        font-weight: 600;
        color: $color3;
        margin: 0 22px;
        text-shadow: 1px 2px #ada4a4;
    }
}