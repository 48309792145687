.goal-list-info {
    display: flex;
    justify-content: space-between;
    color: #969696;
    min-height: 44px;

    h3 {
        font-size: 12px;
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        h3 {
            span {
                color: #4f7623;
            }
        }
    }

    .points {
        position: fixed;
        transform: translateX(-50%);
        font-size: 22px;
        bottom: 0;
        left: 47%;
        z-index: 1;
        .point-badge {
            background-color: #A9A9A9;
            border-color: #A9A9A9;
            border: 1px solid transparent;
            border-radius: 1rem;
            padding: 0.25rem 0.5rem;
            color: #ffffff;
        }
    }

    .import-goals {
        cursor: pointer;
        display: flex;
        align-items: center;

        &:hover {
            h3 {
                text-decoration: underline;
            }
        }

        svg {
            margin-right: 12px;
        }

        h3 {
            margin: 0;
            padding-right: 12px;
        }
    }
}
