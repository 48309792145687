$primaryColor: #F05b13;
$secondaryColor: #5F6062;
$color3: #FFF;
$primaryColorRGB : rgba(240, 91, 19, 0.25);
$primaryBoxShadow: 0 0 0 0.2rem $primaryColorRGB;
$success: #28A745 !default;
$info: #17A2B8 !default;
$warning: #FFC107 !default;
$danger: #DC3545 !default;
$light: #F8F9FA !default;
$dark: #343A40 !default;
$brandBlue: #00B6DE;
$breakpoints: (mobile: 576px,
    tablet: 768px,
    laptop: 992px,
    desktop: 1200px);