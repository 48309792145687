@import '../../scss/mixins';

.unauthorized {
    @include cardEffect;
    @include padding;
    margin: 20px;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .circle {
        background: #f37321;
        border-radius: 100%;
        height: 150px;
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;

        h1 {
            color: #FFF;
            position: absolute;
        }
    }

    h3 {
        @include md-text(32px);
        margin-top: 42px;
        color: grey;
        font-weight: 500;
    }

    p {
        @include md-text(18px);
    }
}