@import '../../scss/mixins';

.hor-cycle-status {
    display: flex;
    flex-direction: column;
    padding: 12px 12px 0 12px;
    width: 230px;
    min-height: 130px;
    transition: box-shadow 250ms linear;

    &.active,
    &:hover {
        border-radius: 6px;
        background-color:#E0E0E0;
        box-shadow: 6px 6px 14px #5f6062;
    }

    .main-section {
        display: flex;
        justify-content: space-evenly;
        .section2 {
            display: flex;
            flex-flow: nowrap column;
            justify-content: flex-start;
            min-width: 120px;
            margin-left: 12px;

            h5 {
                @include md-text(18px);
                margin: 0;
            }

            h6 {
                @include rg-text(14px);
                color: #5c5c5c;
                margin: 0;
            }

            .quarter {
                padding-bottom: 4px;
            }

            .status {
                @include md-text(14px);
                font-weight: 500;
                padding-bottom: 6px;
            }
            .date {
                color: #7c7c7c;
            }
        }
        .horizontal-bar {
            display: block;
            width: 90%;
            border-bottom: 1px solid #a5a5a5;
            margin: 8px 0;
        }
    }

    .sub-section {
        padding-top: 2px;
        display: inline-flex;
        justify-content: flex-end;
        margin-right: 4px;
        h6 {
            @include rg-text(14px);
            color: #5c5c5c;
        }
    }
}
