.loader {
    background: #FFF;
    position: absolute;
    top: 40%;
    left: 50%;

    span {
        width: 100px;
        height: 100px;
    }
}