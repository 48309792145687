@import '../../../scss/mixins';

.react-select-category {
    width: 140px;

    .select__control {
        box-shadow: none;
        @include select-control-focused;

        &:focus {
            outline: none;
            box-shadow: none;
        }

        .select__indicators {
            .select__indicator-separator {
                display: none;
            }
        }
    }
}
@include select-menu;