@import '../../scss/mixins';
@import '../../scss/variables';

.import-goals-modal {
    .modal-content {
        @include padding();

        .title {
            display: flex;
            position: relative;
            justify-content: center;

            h3 {
                @include md-text(24px);
                font-weight: 500;
            }

            a {
                position: absolute;
                cursor: pointer;
                right: -4px;
                top: 4px;
                font-size: 12px;
            }
        }

        .upload-file {
            height: auto;
            min-height: 100px;
            display: flex;
            flex-direction: column;

            input[type='file'] {
                display: none;
            }

            .custom-file-upload {
                border: 1px solid #ccc;
                display: inline-flex;
                justify-content: center;
                padding: 6px 12px;
                cursor: pointer;
                width: 40%;
                margin: 15px auto;
                border-radius: 8px;
                color: $secondaryColor;
                background: #dfdfdf;
                &.error {
                    border: 1px solid $danger;
                }
            }

            .filename {
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;
                margin: 10px;
                padding: 12px;

                div {
                    display: flex;
                    align-items: baseline;

                    svg {
                        font-size: 18px;
                        margin: auto 12px;
                        fill: green;
                    }

                    h3 {
                        margin: auto;
                        @include md-text(16px);
                    }
                }

                svg {
                    font-size: 24px;
                    fill: '#000';
                }
            }

            span {
                font-size: 15px;
                color: $primaryColor;
            }
        }

        .goalList {
            display: table;
            margin-top:10px;
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            thead {
                background: #f8f8f8;
                font-weight: 500;
                tr {
                    th {
                        background-color: #5f6062;
                        color: #fff;
                        box-shadow: 0px 15px 20px 0px rgba(119, 119, 119, 0.85);
                    }
                }
            }
            tbody {
                tr {
                    .action-buttons {
                        padding: 18px 12px;
                        cursor: pointer;
                        svg {
                            margin: 0 4px;
                            padding-top: 2px;
                            transition: transform 250ms ease-out;
                            will-change: transform;
                            &:hover {
                                transition: transform 150ms;
                                transform: translateY(2px);
                            }
                        }
                    }
                }
            }
        }

        .buttons {
            display: inline-flex;
            justify-content: space-around;
            margin-top:10px;
            .btn {
                width: 30%;
                min-width: 100px;
            }

            span {
                display: inline-flex;
                margin: 0 5px;
            }
        }
        .alert {
            margin-top: 12px;
        }
    }
}

.importModalWidth {
    max-width: 1500px;
}

.invalid-row {
    border: 1px solid #dc3545 !important;
    box-shadow: 0px 0px 8px #dc3545;
}
