@import '../../scss/mixins';

.goal-scoring {
    display: flex;
    justify-content: space-between;
    background: #D5D5D5;
    border-radius: 2px;
    margin-top: 12px;

    h4 {
        @include md-text(14px);
        font-weight: 500;
        padding: 8px;
        margin: 0;

        span {
            font-size: 18px;
        }
    }
}