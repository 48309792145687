@import '../../scss/mixins';

.header {
    display: flex;
    padding: 12px;
    justify-content: flex-end;
    margin-top: 12px;
    .header-logo {
        display: none;
    }

    span {
        padding: 0 10px;
        cursor: pointer;
        margin: auto 0px;
    }

    .user-name {
        padding: 0;
        a {
            color: black;
        }
        #collapsible-nav-dropdown {
            &.nav-link {
                padding-left: 0;
            }
        }

        .dropdown-menu {
            padding: 0;

            a {
                padding: 12px;
                text-align: center;
            }
        }
    }
}
@include media-queries('mobile') {
    .header {
        .header-logo {
            margin-right: auto;
            display: flex;
            align-items: center;

            .toggle {
                display: flex;
                flex-direction: column;
                margin-right: 12px;
                
                span {
                    background-color: #000;
                    padding: 2px;
                    margin: 2px 6px 2px 0;
                    width: 24px;
                }
            }
        }

        .user-name {
            display: none;
        }
    }
}
