.eoq-result {
    .form-control{
        min-height: 35px;
    }
    .text {
        border: 1px solid #ced4da;
        border-radius: 4px;
        min-height: 36px;
        padding: 4px 12px;
        word-wrap: break-word;
        width: 280px;
        font-size: 15px;
        color: #000;
        background-color: #fff;
        resize: none;
        p {
            box-sizing: content-box;
            margin-bottom: 0;
            color:#495057;

            a {
                color: #007bff;
            }
        }
        .alert-msg {
            color: #dc3545;
        }
        &.disabled {
            background-color: #e9ecef;
            opacity: 1;
        }
    }
}